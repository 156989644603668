<template>
    <div>
        <vx-card id="searchTable" ref="filterCard" title="Retenciones y Devoluciones" class="user-list-filters mb-8">
            <div class="vx-row">
                <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                    <label class="text-sm opacity-75">Escuela</label>
                    <v-select v-model="idSchool" :clearable="false" :options="schoolOptions" :reduce="idSchool => idSchool.value" v-validate="'required'" data-vv-as="Escuela" name="school" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                    <span class="text-danger text-sm" v-show="errors.has('school')">{{ errors.first('school') }}</span>
                </div>
                <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                    <label class="text-sm opacity-75">Nombre</label>
                    <vs-input :dir="$vs.rtl ? 'rtl' : 'ltr'" name="nombre" v-model="firstNameFilter" v-validate="'min:3'" class="mb-4 md:mb-0 w-full" type="text"/>
                    <span class="text-danger text-sm" v-show="errors.has('nombre')">{{ errors.first('nombre') }}</span>
                </div>
                <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                    <label class="text-sm opacity-75">Documento</label>
                    <vs-input :dir="$vs.rtl ? 'rtl' : 'ltr'" name="documento" v-model="cardNumberFilter" v-validate="'min:3'" class="mb-4 sm:mb-0 w-full"/>
                    <span class="text-danger text-sm" v-show="errors.has('documento')">{{ errors.first('documento') }}</span>
                </div> -->
                <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-5 ag-grid-table-actions-right">
                    <vs-button id="searchButton" class="mb-4 md:mb-0" @click="loadAccountTable" :disabled="!isFormValid">Aplicar</vs-button>
                </div> -->
            </div>
        </vx-card>

        <div class="vx-card p-6">
          <div slot="no-body" class="tabs-container px-6 pt-6" v-show="idSchool">
            <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
              <vs-tab label="Retenciones" icon-pack="feather" icon="icon-layers">
                <div class="tab-text">
                  <pending-table @updateSearchQuery="updateSearchQuery" :school_id="idSchool"/>
                </div>
              </vs-tab>
              <vs-tab label="Devoluciones" icon-pack="feather" icon="icon-archive" @click="searchQuery = ''">
                <div class="tab-text">
                  <processed-table :school_id="idSchool" :searchQuery="searchQuery" />
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import { AgGridVue } from "@ag-grid-community/vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import reportModule from "@/store/report/moduleReport.js";
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import PendingTable from './WithholdingsPending.vue'
import ProcessedTable from './WithholdingsProcessed.vue'

export default {
    components: {
        vSelect,
        AgGridVue,
        AllCommunityModules,
        PendingTable,
        ProcessedTable
    },
    data() {
        return {
            idSchool: null,
            schoolOptions: [],

            activeTab: 0,
            searchQuery: "",
        }
    },
    methods: {
      isFormValid() {
        return !this.errors.any() && (this.idSchool > 0)
      },
      updateSearchQuery(q) {
        this.searchQuery = q
        this.activeTab = 1
      },
      getSchools() {
          if(this.schoolOptions && this.schoolOptions.length) return

          this.$store.dispatch(`dataListInstitutions/getSchools`)
          .then(() => {
              const schools = this.$store.getters['dataListInstitutions/getSchoolsInGop'];
              schools.forEach(element => {
                  this.schoolOptions.push({label: element.name, value: element.id})
              });
          })
          .catch(err => console.log(err))

      },
    },
    created() {
        if (!reportModule.isRegistered) {
            this.$store.registerModule("reportModule", reportModule);
            reportModule.isRegistered = true;
        }
        if(!moduleDataListInstitutions.isRegistered) {
            this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
            moduleDataListInstitutions.isRegistered = true
        }
        if (!moduleUserManagement.isRegistered) {
            this.$store.registerModule("userManagement", moduleUserManagement);
            moduleUserManagement.isRegistered = true;
        }

        this.getSchools();
    },
}
</script>
