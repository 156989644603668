<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="popupActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4>Liberar retención</h4>
        <button
        type="button"
        class="cursor-pointer flex items-center justify-center bg-transparent border-0"
        @click="onRemovePopup()"
        >
          <feather-icon icon="XIcon" class="cursor-pointer"></feather-icon>
        </button>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="px-6">
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Monto Pagado"
          disabled
          v-model="amount"
          class="mt-5 w-full"
          data-vv-as="Monto a liberar"
          name="amount" />

          <date-picker :language="languages.es" v-model="dataPayDate" :first-day-of-week="1" placeholder="Fecha de Pago" class="mt-5 w-full" name="item-pay_date"></date-picker>


          <template v-if="dataReceipt">
            <div v-if="!this.isPdf" class="img-container w-64 mx-auto flex items-center justify-center mt-5">
              <img  :src="dataReceipt" alt="img" class="responsive">
            </div>
            <div v-else class="img-upload">
              <h4 class="text-archive"><i translate="translate" class="material-icons notranslate">description</i><span>{{this.file_name}}</span></h4>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('product_image')">{{ errors.first('product_image') }}</span>

            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*,application/pdf"
                name="product_image"
              >
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
              >
                Actualizar Comprobante
              </vs-button>
            </div>
            <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
              {{ image_error }}
            </vs-alert>
          </template>

          <div class="upload-img" v-if="!dataReceipt">
            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*,application/pdf" />
            <vs-button class="mt-5 bg-primary" @click="$refs.uploadImgInput.click()">Subir comprobante</vs-button>
          </div>
      </div>

    </VuePerfectScrollbar>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="isLoading">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="onRemovePopup()">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import DatePicker from "vuejs-datepicker";
  import * as lang from 'vuejs-datepicker/src/locale/Language';

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      popupActive: {
        type: Boolean,
        required: true
      },
      onFinish: {
        type: Function,
        required: false
      }
    },
    data() {
      return {
        languages: lang,
        id: null,
        idServicePaymentRequest: null,
        amount: 0,
        dataPayDate: new Date(),
        isLoading: false,
        image_loaded: false,
        image_error: '',
        isPdf: false,
        file_name: "",
        dataReceipt: "",

        product_image: {
          size: '',
          width: '',
          height: ''
        },

        settings: {
          maxScrollbarLength: 60,
          wheelSpeed: .60,
        },
      }
    },
    watch: {
      popupActive(val) {
        if (!val) {
          this.dataPayDate = new Date()
          this.isLoading = false
          this.image_loaded = false
          this.image_error = ''
          this.isPdf = false
          this.file_name = ""
          this.dataReceipt = ""

          this.product_image = {
            size: '',
            width: '',
            height: ''
          }
          return
        }
        let { amount, id_service_payment_request, id } = JSON.parse(JSON.stringify(this.data))
        this.id = id
        this.idServicePaymentRequest = id_service_payment_request
        this.amount = amount
      }
    },
    methods: {
      onRemovePopup() {
        this.$emit('updatePopupActive', false)
      },
      updateCurrImg(input) {
        this.image_loaded = false;
        this.image_error = '';
        if (input.target.files && input.target.files[0]) {
          var reader = new FileReader();

          if(input.target.files[0]['type'] === 'application/pdf') {
            if(input.target.files[0].size > 2000000) {
              this.image_error = "El archivo excede el tamaño máximo (2 MB)";
              return false;
            }
            reader.onload = e => {
              this.dataReceipt = e.target.result;
              this.image_loaded = true;
              this.file_name = input.target.files[0].name;
              this.isPdf = true;
            }
          } else {
            this.isPdf = false;
            this.product_image.size = input.target.files[0].size;
            reader.onload = e => {
              let img = new Image();
              img.onload = () => {
                this.product_image.width = img.width;
                this.product_image.height = img.height;
                if (this.product_image.width > 3000 ) {
                  this.image_error = 'La imágen debe tener un tamaño máximo de 3000px';
                } else {
                  if (this.product_image.size > 1000000){
                    this.image_error = 'La imágen excede el tamaño máximo (1 MB).';
                  } else {
                    this.dataReceipt = e.target.result
                    this.image_loaded = true;
                  }
                }
              }
              img.src = e.target.result;
            }
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      async submitData() {
        this.isLoading = true
        await this.$store.dispatch('reportModule/returnRetention', {
          id_withholding: this.id,
          id_service_payment_request: this.idServicePaymentRequest,
          receipt: this.dataReceipt,
          pay_date: new Date(this.dataPayDate).toISOString().split("T")[0]
        })
        await this.$props.onFinish()
        this.isLoading = false
        this.onRemovePopup()
      },
    },
    computed: {
      popupActiveLocal: {
        get() {
          return JSON.parse(this.popupActive)
        },
        set() {
          this.$emit('updatePopupActive', this.popupActive)
        }
      },
      item() {
        return this.data
      },
    },
    components: {
      VuePerfectScrollbar,
      DatePicker
    },
  }
</script>

<style lang="scss" scoped>

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
