<template>
  <vs-popup fullscreen :title="getTitle" :active.sync="popupActiveLocal">
    <iframe 
      v-if="isPdf" 
      :src="receiptUrl+'#toolbar=0'" 
      frameborder="0" 
      style="width:100%; 
      height:85vh;">
    </iframe>
    <vs-row 
      v-else 
      vs-align="center" 
      vs-type="flex" 
      vs-justify="space-around" 
      vs-w="12"
    >
      <img :src="receiptUrl" />
    </vs-row>
  </vs-popup>
</template>

<script>

  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      popupActive: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        isPdf: false,
        receiptUrl: ''
      }
    },
    methods: {
      onRemovePopup() {
        this.$emit('updatePopupActive')
      },
    },
    watch: {
      popupActive(val) {
        const data = this.$props.data
        if (!val) {
          this.isPdf = false
          this.receiptUrl = ''
        } else {
          const extension = data.withholding_return_receipt.split('.').at(-1)
          this.isPdf = extension === 'pdf'
          this.receiptUrl = `${this.$baseUrlImg}${data.withholding_return_receipt}`
        }        
      }
    },
    computed: {
      popupActiveLocal: {
        get() {
          return JSON.parse(this.popupActive)
        },
        set() {
          this.$emit('updatePopupActive')
        }
      },
      getTitle() {
        return `Retención Nº ${this.$props.data.id}`
      }
    },
    item() {
      return this.data
    }
  }
</script>