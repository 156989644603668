<template>
  <div>
    <button 
      class="bg-primary py-2 px-2 outline-none border-none rounded text-white cursor-pointer" 
      @click="onClick()"
      v-if="this.params.isPending"
    >
      Liberar retención
    </button>
    <button v-if="this.params.data.withholding_return_receipt" class="mt-1 bg-transparent border-none w-12 h-12" @click="onClick()">
      <feather-icon icon="FileTextIcon" svgClasses="h-6 w-6 hover:text-primary cursor-pointer" />
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      isPending: {
        type: Boolean,
        required: false
      }
    },
    name: 'CellRendererWithHoldings',
    methods: {
      onClick() {
        this.params.onShowPopup(this.params.data)
      },
    }

  }
</script>